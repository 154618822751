/* You can add global styles to this file, and also import other style files */
body, html {
    margin: 0;
    padding: 0;
    @apply bg-slate-50 dark:bg-slate-900;
    min-height: 100vh;
}

html {
    overflow-y: scroll;
    scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import './app/styles/input.scss';


h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 34px;
}

h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
}

h4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
}

input {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}
p, a, div, select {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: unset;
}

.card {
    border: none;
    padding: 18px 0;
}

svg {
    width: 100% !important;
    height: auto;
}

@keyframes horizontal-shaking {
    0% {
        transform: translateX(0)
    }

    25% {
        transform: translateX(7px)
    }

    50% {
        transform: translateX(-7px)
    }

    75% {
        transform: translateX(7px)
    }

    100% {
        transform: translateX(0)
    }
}

.shake {
    animation: horizontal-shaking 0.2s;
}

mat-dialog-content, mat-dialog-actions {
    @apply dark:bg-slate-800;
}

.mat-mdc-dialog-content {
    max-height: unset !important;
}

.cdk-overlay-pane {
    max-width: 90vw !important;
}
